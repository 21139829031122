<template>

    <div>
      
      <navBar/>
  
      <div class="textContainer">
  
        <div class="titleContainer">
  
          <h2 class="subTitle">Baixe o E-book</h2>
          
          <h1 class="title">Estratégias Vencedoras para Operações de Cobrança Digital!
            </h1>
        </div>
  
        <!--#region TEXTO PAG PRINCIPAL ========-->
        <div  class="infoText">
  
          <h2 style="margin-bottom: 25px">
            A cobrança digital é uma abordagem inovadora que tem transformado as operações de recuperação de dívidas em empresas de todos os tamanhos.
          </h2>

          <h2 style="margin-bottom: 25px">
            Neste e-book, vamos explorar as melhores estratégias para otimizar suas operações de cobrança por meio da tecnologia e do ambiente digital que a SharkData oferece. Aprenderemos como maximizar a eficiência, reduzir a inadimplência e melhorar a satisfação do cliente, tudo isso enquanto mantemos a empatia e a abordagem humanizada.
          </h2>

          <h2>Preencha o formulário para realizar o download gratuito do e-book.</h2>
  
        </div>
        <!--#endregion-->
  
      </div>
  
      <!--==================================================================================-->
      
      <div class="formBlock">
        <div v-for="campos in formFields.Campos" :key="campos.Id">
         
          <label :for="campos.NomeCampo">{{ campos.NomeCampo }}*</label>
          <input  type="text" id="camposTexto" v-model="respostas[campos.Id]"/>
        </div> 
        <button class="downloadButton" :disabled="isFormEmpty" @click.prevent="submitForm()">Fazer download do material</button>
  
      </div>
  
    </div>  
  
  </template>
  
  <!--===================================================================================-->
  
  <script>
  
    import axios from  'axios'
    import navBar from "@/components/navBar.vue"
  
    export default{
  
      components:{
  
        navBar,
  
      },
  
      data(){
        return{
  
          respostas: {},
          formFields: {},
          disabled: false,
          
          //LINK DE DOWNLOAD QUE DEVE SER TROCADO PARA O DO EBOOK:
			fileUrl: process.env.VUE_APP_EBOOK_ESTRATEGIAS_COBRANCA_DIGITAL_LINK ?? 'https://sdomni.blob.core.windows.net/apisharkproducao/ebooks/ebook-estrategias-cobranca-digital.pdf'
        }
      },
      
      
      created(){
        this.getFormFields();
      },
  
      computed:{
  
        isFormEmpty(){
  
          if (this.formFields.Campos == null){
            return true
          }
          return this.formFields.Campos.some(campo => this.respostas[campo.Id] == null || this.respostas[campo.Id].trim() == '')
        },
  
      },
  
      methods:{
  
        downloadFile() {
          console.log('downloadFile called');
          const downloadLink = document.createElement('a');
          downloadLink.href = this.fileUrl;
          downloadLink.setAttribute('download', '');
          downloadLink.setAttribute('target', '_blank');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        },
  
        getFormFields(){
			axios.get(`${process.env.VUE_APP_EBOOK_ESTRATEGIAS_COBRANCA_DIGITAL_API_ENDPOINT ?? "https://localhost:44310"}/api/formularios/BuscarFomulario/${process.env.VUE_APP_EBOOK_ESTRATEGIAS_COBRANCA_DIGITAL_FORMULARIO_ID ?? "e1f6a528-e0c9-4047-afe9-d8cc0240db2f"}`)
          .then(response =>{ 
            this.formFields = response.data
          })
          .catch(error =>{
            console.log(error)
          })
        },
        
        limparFormulario(){
          this.respostas = {};
        },
  
        submitForm(){
          let dados = {
          FormularioId: this.formFields.Id,
          EmpresaId: this.formFields.EmpresaId,
          CamposRespostasDTOs: this.formFields.Campos.map(campo =>
            ({
              FormularioCampoId: campo.Id,
  
              NomeCampo: campo.NomeCampo,
  
              Resposta: this.respostas[campo.Id],
  
              CampoTipo: campo.FormularioCampoTipo  
              })),
            };
  
        
  
			axios.post(`${process.env.VUE_APP_EBOOK_ESTRATEGIAS_COBRANCA_DIGITAL_API_ENDPOINT ?? "https://localhost:44310"}/api/formularios/CriarFormularioRespostas/`, dados)
          .then(response => {
            this.downloadFile()
            this.limparFormulario()
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
  
        }
  
      }
  
    }
  
  </script>
  
  <!--===================================================================================-->
  
  <style>
    
    body{
      overflow-x: hidden;
      background-color: #124A8A !important;
    }
  
    .textContainer{
  
      display: block;
      width: 50vw;
      float: left;
    }
  
    #download-link {
    display: none;
  }
  
    .titleContainer{
      border: 1px #63717D solid;
      cursor: default;
      margin-left: 0;
      padding: 15px;
      padding-left: 0;
      background-color: #1B75C4;
      color: white;
      text-align: left;
      width: 95%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      min-width: 250px;
    }
  
    .title{
      font-size: 40px;
      padding-left: 50px;
    }
  
    .subTitle{
      min-width: 250px;
      margin-left: 0;
      padding: 10px;
      padding-left: 50px;
      width: 30%;
      background-color: #C4641B;
      font-size: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  
    .infoText h2{
  
      color: white;
      font-size: 18px;
  
    }
  
    .infoText{
      min-width: 250px;
      padding-top: 20px;
      padding-left: 50px;
      width: 80%;
    }
  
    .formBlock{
      border: 1px #63717D solid;
      min-width: 250px;
      background-color: #1B75C4;
      width: calc(50vw - 120px);
      margin-left: 50px;
      padding: 30px;
      text-align: left;
      border-radius: 20px;
      color: white;
      display: block;
      float: left;
      
    }
  
    .formBlock input{
      display: block;
      border: none;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      margin-top:5px;
      width: 90%;
      height: 40px;
      padding: 10px;
    }
  
    .formBlock input:focus{
      outline: none;
    }
    
  
    .formBlock label{
  
        margin-left:5%;
  
    }
  
    .downloadButton{
  
      text-align: center;
      margin-left: 5%;
      border:none;
      border-radius: 10px;
      padding:10px;
      width: 90%;
      background-color: #0CB423;
      color: white;
      margin-top: 50px;
  
    }
  
    .downloadButton:disabled{
       opacity: 50%;
  
    }
  
    @media screen and (max-width: 770px){
  
  
    .titleContainer{
  
      width: 90%;
    }
    .textContainer{
  
      display: block;
      width: 100vw;
    }
  
    .formBlock{
      margin-top: 40px;
      width: calc(90% - 40px);
      margin-right: 0;
      margin-bottom: 50px;
  
    }
  
    .title{
  
      font-size: 25px;
      max-width: 100%;
    }
  
    .infoText{
      width: 90%;
      font-size: 15px;
    }  
  
  
    }
  
  
  </style>