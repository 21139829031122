import Vue from 'vue'
import VueRouter from 'vue-router'
import GPTView from '../views/chatGPT.vue'
import dispMassiveView from '../views/disparosMassivos.vue'
import integracaoGPTView from '../views/integracaoGPT.vue'
import captacaoLeads from '../views/captacaoLeads.vue'
import boasPraticasWhatsapp from '../views/boasPraticasWhatsapp.vue'
import estrategiasCobranca from '../views/estrategiasCobranca.vue'
import automacaoWhatsapp from '../views/automacaoWhatsapp.vue'
import revAtendimento from '../views/revAtendimento.vue'
import CanaisDigitaisCrm from '../views/CanaisDigitaisCrm.vue'
import MelhoresRedesSociais from '../views/MelhoresRedesSociais.vue'
import AtendimentoWhatsapp from '../views/AtendimentoWhatsapp.vue'
import TodosOsCanais from '../views/TodosOsCanais.vue'
import AscencaoIa from '../views/AscencaoIa.vue'

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  routes: [   
    {
      path: '/all-channels',
      name: 'TodosOsCanais',
      component: TodosOsCanais
    },     
    {
      path: '/ascencao-ia',
      name: 'AscencaoIa',
      component: AscencaoIa
    },    
    {
      path: '/ebook-chatgpt',
      name: 'chatGPT',
      component: GPTView
    },

    {
      path: '/disparos-massivos',
      name: 'disparosMassivos',
      component: dispMassiveView
    },

    {
      path: '/integracao-GPT',
      name: 'integracaoGPTView',
      component: integracaoGPTView
    },

    {
      path: '/captacao-leads',
      name: 'captacaoLeadsView',
      component: captacaoLeads
    },

    {
      path: '/boas-Praticas-Whatsapp',
      name: 'boasPraticasWhatsappView',
      component: boasPraticasWhatsapp
    },

    {
      path: '/estrategias-Cobranca',
      name: 'estrategiasCobrancaView',
      component: estrategiasCobranca
    },

    {
      path: '/automacao-whatsapp',
      name: 'automacaoWhatsapp',
      component: automacaoWhatsapp
    },

    {
      path: '/revolucao-atendimentos',
      name: 'revAtendimento',
      component: revAtendimento
    },
    
    {
      path: '/canais-digitais-crm',
      name: 'CanaisDigitaisCrm',
      component: CanaisDigitaisCrm
    },

    {
      path: '/melhores-redes',
      name: 'MelhoresRedesSociais',
      component: MelhoresRedesSociais
    },     
    
    {
      path: '/atendimento-whatsapp',
      name: 'AtendimentoWhatsapp',
      component: AtendimentoWhatsapp
    }, 

  ]
})



