<template>
    <div class="topnav">
        <a class="sharkButtom" href="https://www.sharkdata.com.br" target="_blank">
            <img src="@/assets/logo-positivo.png" alt="">
        </a>
        
    </div>
</template>

<script>

export default {
    name: 'navBar',

}
</script>

<style>

    .topnav {
        background-color: transparent;
        overflow: hidden;
        width: 100%;
    }

    /* Style the links inside the navigation bar */
    .topnav a {
        float: left;
        padding: 14px 16px;
    }

    a img {
        margin-top: 15px;
        margin-left: 35px;
        margin-bottom: 20px;
        width: 100px;
        height: auto;
    }

    @media screen and (max-width: 770px) {

        a img {

            margin-left: 5px;
        }
        
    }

</style>
